@import "../../../variables.scss";

.card {
  background-color: $video-card-bg;
  padding: 13px;
  border-radius: 6px;
  .container {
    .titleSection {
      padding-top: 6px;
      .icon {
        svg,
        .infoIcon {
          font-size: 14px;
          color: $font-color;
          padding-left: 5px;
          cursor: pointer;
        }
        .infoIcon {
          font-size: 17px;
          padding-left: 0px;
        }
      }
      .title {
        font-size: 16px;
        font-weight: 650;
        color: $title-color;
        padding-bottom: 3px;
        font-family: SourceSansPro-SemiBold;
        padding-right: 9px;
      }
      .description {
        margin: unset;
        font-size: 13px;
        padding-bottom: 5px;
        font-family: SourceSansPro-Regular;
        color: $title-color;
        font-weight: 400;
      }
    }
  }
  .thumbnailSection {
    // max-width: fit-content;
    // width: inherit;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    .play,.playBg{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 1;
    }
    .playBg{
        z-index: 0;
    }
  }

  .thumbnail {
    width: 100%;
    object-fit: cover;
  }
  @media only screen and (max-width: 360px) {
    .thumbnail {
      border-radius: 6px;
    }
    .bottomIconSection{
      display: none;
    }
  }

  @media only screen and (min-width: 360px) and (max-width: 600px) {
    .thumbnailSection {
      //   margin: auto;
      background-color: $home-bg;
    }
    .container {
      display: block;
    }
    .thumbnail {
      height: 140px;
      width: 298px;
    }
    .bottomIconSection{
      display: none;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 900px) {
    .titleSection {
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: unset !important;
    }
    .icon{
      display: none;
    }

    .bottomIconSection{
      display: flex;
    }
  }

  @media (min-width: 600px) {
    .thumbnailSection {
      max-width: fit-content;
    }
    .thumbnail {
      border-radius: 6px;
    }
  }

  @media (min-width: 900px) {
    .thumbnailSection {
      max-width: unset;
      //   margin: auto;
    }
    .thumbnail {
      height: 139px;
      width: auto;
      border-radius: 0px;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1200px) {
    .container {
      display: block;
    }
    .thumbnailSection {
      //   margin: auto;
      background-color: $home-bg;
    }
    .bottomIconSection{
      display: none;
    }
  }

  @media (min-width: 1200px) {
    .thumbnail {
      height: 139px;
      width: 100%;
      border-radius: 6px;
    }
    .bottomIconSection{
      display: none;
    }
  }
}
