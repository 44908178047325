@import "../../../variables.scss";

.cardView {
  .heading {
    font-weight: 650;
    font-style: normal;
    font-size: 16px;
    color: $title-color;
    font-family: SourceSansPro-SemiBold;
    padding: 20px 17px 0px 17px;
    text-transform: capitalize;
    width: 78%;
  }

  .content {
    padding: 3px 17px 20px 17px;
    .paragraph {
      font-size: 13px;
      font-family: SourceSansPro-Regular;
      margin-top: 0;
      color: $title-color;
    }
    .topicSection {
      margin-top: 10px;
      .topic {
        font-size: 13px;
        color: $title-color;
        font-family: SourceSansPro-Regular;
      }
      .para {
        font-size: 13px;
        color: $title-color;
        font-weight: 650;
        font-family: SourceSansPro-SemiBold;
        text-transform: capitalize;
      }
    }
    .linkIcon{
      margin-left: 7px;
      font-size: 15px;
      color: #40415d;
    }
  }
}
