@import "../../variables.scss";


.playlist{
    padding-top: 15px;
    // display: flex;
    // justify-content: center;
    .createPlaylist{
        background-color: white;
        width: 300px;
        padding: 14px;
        border-radius: 10px;
        margin-top: 20px;
    }
    .addVideosToPlaylist{
        background-color: white;
        width: 300px;
        padding: 12px;
        border-radius: 10px;
    }
    .circlePlusIcon,.penIcon{
        color: #ffffff;
        font-size: 18px;
        padding-left: 10px;
        margin-bottom: 4px;
        cursor: pointer;
    }
    .penIcon{
        color: #ffffff;
        padding-left: 10px;
    }
}
