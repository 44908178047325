@import "../../variables.scss";

body {
  background-color: $home-bg;
}

.home {
  padding-top: 22px;
  .tab {
    border-bottom: 1px solid #ffffff40;
    text-transform: capitalize;
    font-family: SourceSansPro-Regular;
  }
}

.logo {
  padding-bottom: 20px;
}

.singleTab {
  background-color: $button-active-text-color;
}

.MuiTabPanel-root.tabContent {
  padding: unset;
}
.videoSize{
  width: 50%;
}
.modalContent {
  .videoPlayer{
    justify-content: center;
    height: calc(100vh - 150px);
    
  }
  .footerActions {
    background-color : rgba(26, 26, 26, 1);
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    .button {
      background-color: black;
      border-radius: 25px;
      padding: 6px 15px;
      color: #ffffff;
      border: 1px solid rgba(255, 255, 255, 0.2509803922);
      text-transform: inherit;
    }
  }
}

@media only screen and (max-width: 900px) {
  .videoSize{
    width: 90%;
  }
}