@import "../../../variables.scss";

.button {
  .btn {
    font-weight: 650;
    font-size: 16px;
    font-family: SourceSansPro-SemiBold;
    padding: 17px 15px;
    width: 100%;
    cursor: pointer;
  }
  .activeBtn {
    color: $button-active-text-color;
    background-color: $disabled-color;
    &:hover {
      background-color: $disabled-color;
    }
  }
  .disableBtn {
    color: $disabled-color;
    border: 1px solid $disabled-color;
    background-color: transparent;
    font-weight: 400;
    &:hover {
      background-color: transparent;
    }
  }
}
