@import "../../../variables.scss";

.playListAccordion {
  // background-color: rgba(199, 201, 221, 1);
}
.onthumbnailSmallPlay {
  display: none;
}
.accordion {
  .accordionSummery {
    .MuiAccordionSummary-content {
      display: inline;
      justify-content: space-between;
    }
  }

  .titile {
    color: $title-color;
    font-weight: 650;
    font-size: 16px;
  }
  .filterVideo {
    padding-bottom: 7px;
    border-bottom: 1px solid rgb(176 181 210);
    padding-top: 7px;
    &:last-child {
      border-bottom: none;
    }
    .thumbnailSection {
      //   height: 53px;
      //   width: fit-content;
      cursor: pointer;
    }
    .onthumbnailPlay {
      // background-image: url(../../../images/play.svg);
    }
    .play,
    .playBg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      color: #333333;
    }
    .playBg {
      z-index: 0;
    }
    .thumbnail {
      
    }
  }
  .infoIcon {
    font-size: 13px;
    color: #333333;
    padding-left: 5px;
    cursor: pointer;
  }
  .infoIcon {
    font-size: 17px;
    padding-left: 0px;
  }

  .fontIcon {
    color: #333333;
    padding-left: 5px;
    cursor: pointer;
  }
  .addToPlaylistt{
    background-color: rgb(222, 33, 126);
    margin: 0px 10px;
    padding: 8px 26px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    font-weight: 650;
    font-style: normal;
    font-size: 14px;
    color: rgb(255, 255, 255);
    font-family: SourceSansPro-SemiBold;
    cursor: pointer;
    .searchIcon{
      color: #ffffff;
      padding-right: 8px;
    }
  }

  @media only screen and (max-width: 360px) {
    .thumbnail {
      border-radius: 6px;
      width: 108px;
      height: 53px;
    }
    .bottomIconSection {
      //   display: none;
    }
    .heading {
      // width: unset;
    }
    .onthumbnailSmallPlay {
      display: block;
    }
    .onthumbnailPlay {
      display: none;
    }
  }

  @media only screen and (min-width: 350px) and (max-width: 600px) {
    .thumbnailSection {
      //   margin: auto;
      //   background-color: $home-bg;
      height: 53px;
      width: fit-content;
    }
    .container {
      display: block;
    }
    .thumbnail {
      //   height: 140px;
      //   width: 298px;
      width: 108px;
      height: 53px;
    }
    .bottomIconSection {
      //   display: none;
    }
    .onthumbnailSmallPlay {
      display: block;
    }
    .onthumbnailPlay {
      display: none;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 900px) {
    .titleSection {
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: unset !important;
    }
    .icon {
      //   display: none;
    }

    .thumbnail {
      height: 140px;
      width: 298px;
    }
    .bottomIconSection {
      display: flex;
    }
  }

  @media (min-width: 600px) {
    .thumbnailSection {
      max-width: fit-content;
    }
    .thumbnail {
      border-radius: 6px;
    }
  }

  @media (min-width: 900px) {
    .thumbnailSection {
      max-width: unset;
      //   margin: auto;
    }
    .thumbnail {
      height: 139px;
      width: 298px;
      border-radius: 0px;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1200px) {
    .container {
      display: block;
    }
    .thumbnailSection {
      //   margin: auto;
      //   background-color: $home-bg;
    }
    .bottomIconSection {
      //   display: none;
    }
  }

  @media (min-width: 1200px) {
    .thumbnail {
      height: 139px;
      width: 298px;
      border-radius: 6px;
    }
    .bottomIconSection {
      //   display: none;
    }
  }
}
