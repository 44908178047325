@import "../../../variables.scss";

.modal{
    // font-size: 12px;
    .heading{
        font-weight: 650;
        font-size: 13px;
        color: $title-color;
        font-family: SourceSansPro-SemiBold;
        padding: 12px 16px;
    }
    .input{
        // padding: 6px;
    }
    .playlistTitle{
        font-size: 15px;
        font-weight: 400;
        font-family: SourceSansPro-Regular;
        color: $title-color;
    }
    
    .playlistMain{
        margin-bottom: 20px;
       
    }
}

.label{
    color: $title-color;
    font-weight: 400;
    font-family: SourceSansPro-Regular;
    font-size: 13px;
    padding-bottom: 4px;
}

.footer{
    margin-top: 8px;
   
}
.button{
    width: 55px;
}
// .heading{
//     font-size: 12px;
//     color: black;
// }