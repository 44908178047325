@import "/src/variables.scss";

.container {
  color: #FFFFFF;
  // bottom: 0;
  display: flex;
  flex-wrap: wrap;
  font-family: 'SourceSansPro-Regular', 'Source Sans Pro', sans-serif;
  font-size: .9rem;
  font-weight: 400;
  font-style: normal;
  justify-content: center;
  margin-top: 0rem;
  overflow-y: hidden;
  padding-bottom: 1rem;
  position: absolute;
  text-align: center;
  width: 100%;
}

a.link {
  color: #FFFFFF;
  text-decoration: none;
  font-weight: 400;
  font-size: .9rem;
  margin: 0.3rem;
  font-family: 'SourceSansPro-Regular', 'Source Sans Pro', sans-serif;
  cursor: pointer;
}

.break {
  flex-basis: 100%;
  height: 0;
}