@import "../../../variables.scss";

.editPlaylist {
  .heading {
    color: #40405d;
    font-weight: 400;
    font-family: SourceSansPro-Regular;
    font-size: 13px;
    padding: 16px 24px 4px 14px;
  }
  .content {
    padding: 0px 14px 16px 14px;
    .editField {
      width: 100%;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 6px 11px;
      padding: 8px;
      border-radius: 7px;
      background-color: rgb(199, 201, 221);
      .name {
        font-weight: 650;
        font-style: normal;
        color: rgb(34, 36, 66);
        line-height: 18px;
        font-size: 14px;
        font-family: SourceSansPro-SemiBold;
      }
    }
    .editIcon {
      color: $btn-bg;
      font-size: 15px;
      cursor: pointer;
    }
    .deleteIcon {
      color: $btn-bg;
      font-size: 16px;
      cursor: pointer;
    }
    .reorderIcon {
      color: $btn-bg;
      font-size: 19px;
      cursor: pointer;
    }
    .editInputField {
      width: 100%;
      margin: 6px 11px;
      border-radius: 7px;
    }
  }
}
